// Define interfaces that everything else will use
// - DRM configurations
// - Player interfaces
// - Platform-specific types
import type { Component } from 'vue'

// 1. Core Enums - Using enums for better type safety
export enum Platform {
    web = 'web',
    webSafari = 'webSafari',
    ios = 'ios',
    android = 'android'
}

export enum DrmType {
    Widevine = 'Widevine',
    PlayReady = 'PlayReady',
    FairPlay = 'FairPlay'
}

export enum StreamType {
    DASH = 'application/dash+xml',
    HLS = 'application/x-mpegurl'
}

// 2. Base Interfaces
export interface LicenseConfig {
    token: string
    licenseUri: string
    certificateUri?: string
}

export interface BasePlayerConfig {
    autoplay: boolean
    controls: boolean
    fluid: boolean
    responsive: boolean
}

// 3. Platform-Specific Configurations
export interface WebDrmConfig {
    src: string
    type: StreamType
    pallyconToken?: string
    keySystems: {
        [key: string]: {
            licenseUri: string
            certificateUri?: string
            licenseHeaders: {
                'pallycon-customdata-v2': string
            }
        }
    }
}

export interface NativeDrmConfig {
    src: string
    type: StreamType
    pallyconToken: string
    drmType: DrmType
}

// 4. Service Interfaces
export interface PlayerSetup<T = WebDrmConfig | NativeDrmConfig> {
    platform: Platform
    player: Component
    drmConfig: T
    playerConfig: BasePlayerConfig
    drmType: DrmType
}

// 5. Error Handling
export class DrmError extends Error {
    code: string
    platform: Platform
    details?: Error

    constructor({ 
        message, 
        code, 
        platform, 
        details 
    }: { 
        message: string
        code: string
        platform: Platform
        details?: Error 
    }) {
        super(message)
        this.name = 'DrmError'
        this.code = code
        this.platform = platform
        this.details = details
    }
}

// 6. Player State Types
export enum PlayerState {
    IDLE = 'idle',
    LOADING = 'loading',
    READY = 'ready',
    PLAYING = 'playing',
    PAUSED = 'paused',
    ENDED = 'ended',
    ERROR = 'error'
}

export interface PlayerEvents {
    'state-change': PlayerState
    'time-update': number
    'duration-change': number
    'quality-change': string
    'error': Error
    'ready': void
}

export interface PlayerControls {
    play: () => Promise<void>
    pause: () => void
    seek: (time: number) => void
    setQuality: (quality: string) => void
    setVolume: (volume: number) => void
    getCurrentTime: () => number
    getDuration: () => number
}

export interface PlayerEventCallback<T = unknown> {
    (data: T): void
}

export interface iOSNativePlayer {
    play: () => Promise<void>
    pause: () => void
    seek: (time: number) => void
    setVolume: (volume: number) => void
    getCurrentTime: () => number
    getDuration: () => number
    mount: (container: HTMLElement) => void
    destroy: () => void
    on: <T>(event: string, callback: PlayerEventCallback<T>) => void
    isReady: boolean
}

export interface AndroidNativePlayer {
    play: () => Promise<void>
    pause: () => void
    seekTo: (timeMs: number) => void
    setVolume: (volume: number) => void
    getCurrentPosition: () => number
    getDuration: () => number
    mount: (container: HTMLElement) => void
    destroy: () => void
    on: <T>(event: string, callback: PlayerEventCallback<T>) => void
    isReady: boolean
}

// 7. Update PlayerSetup interface
export interface PlayerSetup<T = WebDrmConfig | NativeDrmConfig> {
    platform: Platform
    player: Component  // Import from vue
    drmConfig: T
    playerConfig: BasePlayerConfig
    controls: PlayerControls
    state: PlayerState
}