import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src179013634/src/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src179013634/src/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/codebuild/output/src179013634/src/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_client_rDSYU13oOX from "/codebuild/output/src179013634/src/frontend/plugins/aos.client.ts";
import videojs_1Aev83e59E from "/codebuild/output/src179013634/src/frontend/plugins/videojs.ts";
import posthog_ulvgx15Dgc from "/codebuild/output/src179013634/src/frontend/plugins/posthog.ts";
import quasar_client_5YJ23uG7iJ from "/codebuild/output/src179013634/src/frontend/plugins/quasar.client.ts";
import recaptcha_85gNSCNFUU from "/codebuild/output/src179013634/src/frontend/plugins/recaptcha.ts";
import rollbar_XnPIYyOzed from "/codebuild/output/src179013634/src/frontend/plugins/rollbar.ts";
import router_WMnTGednOQ from "/codebuild/output/src179013634/src/frontend/plugins/router.ts";
import urql_FlK1jaV38U from "/codebuild/output/src179013634/src/frontend/plugins/urql.ts";
import vue_social_share_18K9b0PY2H from "/codebuild/output/src179013634/src/frontend/plugins/vue-social-share.ts";
import vue_tel_input_jrQu9KYfqt from "/codebuild/output/src179013634/src/frontend/plugins/vue-tel-input.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  aos_client_rDSYU13oOX,
  videojs_1Aev83e59E,
  posthog_ulvgx15Dgc,
  quasar_client_5YJ23uG7iJ,
  recaptcha_85gNSCNFUU,
  rollbar_XnPIYyOzed,
  router_WMnTGednOQ,
  urql_FlK1jaV38U,
  vue_social_share_18K9b0PY2H,
  vue_tel_input_jrQu9KYfqt
]