<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
        <div>
            <!-- div wrapper is required for Nuxt layout transitions to work -->
            <FormsOnboarding
                v-if="
                    authStore.isAuthenticated &&
                    authStore.isSubscription &&
                    !$route.path.startsWith('/onboarding') &&
                    !$route.path.startsWith('/success')
                "
            />
        </div>
    </div>
</template>

<script setup>
import Bowser from 'bowser';
import { storeToRefs } from 'pinia';
import { useStore } from '@/store/store';
import { useAuthStore } from '@/store/auth';
import { onMounted } from 'vue';
import { initializeNativeFeatures } from '~/utils/_nativeFeatures';
import { useRoute } from 'vue-router'

useHead({
    title: 'Lucid',
    noscript: [
        {
            innerHTML:
                '<img height=“1” width=“1" style=“display:none” src=“https://www.facebook.com/tr?id=879240497057832&ev=PageView&noscript=1” />',
        },
    ],
    script: [
        {
            innerHTML: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '879240497057832');
            fbq('track', 'PageView');
        `,
        },
    ],
});

const nuxtApp = useNuxtApp();
const store = useStore();
const authStore = useAuthStore();
const router = useRouter();
const intercomInitialized = ref(false);
const route = useRoute()

router.afterEach((to) => {
    // Notify iOS WebView of URL change
    if (window.webkit?.messageHandlers?.urlChanged) {
        window.webkit.messageHandlers.urlChanged.postMessage({
            url: window.location.href,
            path: to.fullPath
        });
    }
    // Handle scroll reset
    window.scrollTo(0, 0);
});

nuxtApp.hook('page:start', () => {
    console.log('page start');
});

nuxtApp.hook('page:finish', async () => {
    console.log('page end');
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isDeviceInfoSent = Boolean(localStorage.getItem('device'));
    if (authStore.isAuthenticated) {
        if (!authStore.userData) {
            await authStore.fetchUser();
        }
        if (!isDeviceInfoSent) {
            const payload = {
                deviceInput: {
                    subscriber: authStore.userData.id,
                    ...browser.parsedResult,
                },
            };
            await store.sendDeviceInfo(payload);
            localStorage.setItem('device', true);
        }
    }
});


onMounted(async () => {
    // Intercom setup (Widget script loading commented out)
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            // var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;

            // Commented out the widget loading script
            /*
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/rmwcmrgw';
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
            */
        }
    })();

    // Boot Intercom without widget
    bootIntercom();

    // Route guards for Intercom updates without widget visibility control
    router.beforeEach(() => {
        // Update Intercom without showing or hiding widget
        window.Intercom('update');
    });
    router.afterEach(() => {
        window.Intercom?.('update');
    });

    await initializeNativeFeatures();
});

function bootIntercom() {
    // Boot Intercom to handle data updates only (no widget display)
    window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'rmwcmrgw',
    });

    /*
    if (router.currentRoute.value.path.startsWith('/ghost')) {
        window.Intercom('update', {
            hide_default_launcher: true,
        });
    }
    */
    intercomInitialized.value = true;
}

const { userData } = storeToRefs(authStore);

watchEffect(() => {
    // Send user data updates to Intercom
    if (
        userData.value &&
        !userData.value.isSharedAccount &&
        intercomInitialized.value &&
        window.Intercom
    ) {
        window.Intercom('update', {
            name: userData.value.name,
            email: userData.value.email,
            user_id: userData.value.id,
        });
    }
});

watch(
    () => authStore.isAuthenticated,
    (authenticated, prevAuthenticated) => {
        if (!authenticated && prevAuthenticated) {
            window.Intercom?.('shutdown'); // Shutdown on logout
            bootIntercom(); // Reinitialize Intercom without widget on login
        }
    }
);

watch(() => route.path, (newPath) => {
  // Force URL assertion on every route change
  window.location.hash = newPath
})

</script>

<style lang="scss">
// base styles
body {
    margin: 0;
    padding: 0;
    background: #000;
    font-family: 'hanskendrick' !important;
}

// transition handling
.page-enter-active,
.page-leave-active {
    transition: opacity 0.4s ease-in-out; //smooth entry + exit
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
   /*filter: blur(1rem); --not performance optimal */
}

.layout-enter-active,
.layout-leave-active {
    transition: opacity 0.3s ease-in-out;
}
.layout-enter-from,
.layout-leave-to {
    opacity: 0;
   /*filter: grayscale(1);--not performance optimal */
}
// font declarations
@font-face {
    font-family: hanskendrick;
    font-weight: 400;
    src: url('~/assets/fonts/hans-kendrick-regular.woff2') format('woff2');
}

@font-face {
    font-family: hanskendrick;
    font-weight: 500;
    src: url('~/assets/fonts/hans-kendrick-medium.woff2') format('woff2');
}

@font-face {
    font-family: hanskendrick;
    font-weight: 700;
    src: url('~/assets/fonts/hans-kendrick-bold.woff2') format('woff2');
}

// utility classes
.text-sanitize {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 36px;
    width: 90% !important;
    white-space: pre-wrap;
}

.bold-font {
    font-weight: 700;
}
// component overrides
:deep(.swiper-button-next) {
    align-items: center !important;
    background: #333 !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    display: flex !important;
    height: 48px !important;
    justify-content: center !important;
    opacity: 0.8 !important;
    transition: all 0.2s !important;
    width: 48px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    z-index: 99 !important;
}

</style>
