<template>
  <div>
    <slot />
  </div>
</template>

<!--
Original implementation with mobile player:

<template>
  <div>
    <slot />
    <MobileAppPlayer 
      v-if="videoStore.currentVideo && isMobileApp"
      :video="videoStore.currentVideo"
    />
  </div>
</template>

<script setup>
import { useDevice } from '~/utils/identifyDevice'
import MobileAppPlayer from '~/components/Video/MobileAppPlayer.vue'
import { useVideoStore } from '~/store/videos'

const { isMobile } = useDevice()
const isMobileApp = isMobile
const videoStore = useVideoStore()
</script>
-->
