import validate from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src179013634/src/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-check-no-redirect": () => import("/codebuild/output/src179013634/src/frontend/middleware/auth-check-no-redirect.ts"),
  auth: () => import("/codebuild/output/src179013634/src/frontend/middleware/auth.ts"),
  cms: () => import("/codebuild/output/src179013634/src/frontend/middleware/cms.ts"),
  ghost: () => import("/codebuild/output/src179013634/src/frontend/middleware/ghost.ts"),
  onboarding: () => import("/codebuild/output/src179013634/src/frontend/middleware/onboarding.ts"),
  pageview: () => import("/codebuild/output/src179013634/src/frontend/middleware/pageview.ts"),
  plans: () => import("/codebuild/output/src179013634/src/frontend/middleware/plans.ts"),
  redirect: () => import("/codebuild/output/src179013634/src/frontend/middleware/redirect.ts"),
  "success-redirect": () => import("/codebuild/output/src179013634/src/frontend/middleware/success-redirect.ts")
}