<template>
    <div class="text-center">
        <h1 class="error-title">Ooops sorry! 🥺</h1>
        <p v-if="error.statusCode === 404" class="error-message" > 
            We're busy fixing a little issue...
        </p>
        <q-btn 
            class="go-back-btn" 
            color="orange-4"
            unelevated
            rounded
            size="lg"
            @click="handleError"
        >
            GO BACK
        </q-btn>
        <p class="bug-journey">
            <span class="path">. . . . . . 🌿</span>
            <span class="bug">🪲</span>
        </p>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';
import useLogs from '~/composables/useLogs';
import { useRouter } from 'vue-router';

const props = defineProps<{
    error: NuxtError;
}>();

const { error: logError } = useLogs();
const router = useRouter();

// Log the error to Rollbar when the component mounts
onMounted(() => {
    const errorDetails = {
        statusCode: props.error.statusCode,
        message: props.error.message,
        stack: props.error.stack,
        url: window?.location?.href,
        // Add any other relevant context
        timestamp: new Date().toISOString()
    };

    logError('Error page rendered', errorDetails);
});

const handleError = () => {
    clearError();
    // First try to go back in history
    if (window.history.length > 2) {
        router.back();
    } else {
        // If no history, go to home page
        router.push('/');
    }
};
</script>

<style scoped lang="scss">
.text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: black;
    color: white;
}

.error-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 300;
}

.error-message {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    opacity: 0.8;
}

.go-back-btn {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
    margin-bottom: 4rem;
    color: black !important;
    opacity: 0.5;
    border-radius: 2rem !important;
}

.bug-journey {
    font-size: 2rem;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    text-align: center;  // Center the path
    
    .path {
        opacity: 0.3;
        letter-spacing: 1rem;
        display: block;
    }
    
    .bug {
        display: inline-block;
        animation: crawl 6s infinite cubic-bezier(0.45, 0, 0.55, 1);
        position: absolute;
        left: 50%;
        z-index: 1;
    }
}

@keyframes crawl {
    0% { 
        transform: translate(-100px, 0) rotate(-10deg); 
    }
    20% { 
        transform: translate(-50px, -20px) rotate(5deg);
    }
    40% { 
        transform: translate(0, 0) rotate(-5deg);
    }
    60% { 
        transform: translate(50px, -20px) rotate(5deg);
    }
    80% { 
        transform: translate(100px, 0) rotate(-5deg);
    }
    100% { 
        transform: translate(-100px, 0) rotate(-10deg);
    }
}
</style>
