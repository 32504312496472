export default {
  collections: () => import("/codebuild/output/src3606977093/src/frontend/layouts/collections.vue").then(m => m.default || m),
  default: () => import("/codebuild/output/src3606977093/src/frontend/layouts/default.vue").then(m => m.default || m),
  "full-height": () => import("/codebuild/output/src3606977093/src/frontend/layouts/fullHeight.vue").then(m => m.default || m),
  "full-height-dark": () => import("/codebuild/output/src3606977093/src/frontend/layouts/fullHeightDark.vue").then(m => m.default || m),
  "full-height-no-footer": () => import("/codebuild/output/src3606977093/src/frontend/layouts/fullHeightNoFooter.vue").then(m => m.default || m),
  login: () => import("/codebuild/output/src3606977093/src/frontend/layouts/login.vue").then(m => m.default || m),
  main: () => import("/codebuild/output/src3606977093/src/frontend/layouts/main.vue").then(m => m.default || m),
  "main-dark": () => import("/codebuild/output/src3606977093/src/frontend/layouts/mainDark.vue").then(m => m.default || m),
  snap: () => import("/codebuild/output/src3606977093/src/frontend/layouts/snap.vue").then(m => m.default || m)
}