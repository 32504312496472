import urql, {
    cacheExchange,
    createClient,
    fetchExchange,
    mapExchange,
} from '@urql/vue';
import { useHttpLoaderStore } from '@/store/http-loader';
import { useAuthStore } from '@/store/auth';
import { XHR_REQUEST_STATES } from '@/interface/http-loader';
import { Cookies } from 'quasar';
import type { AuthCookie } from '~/types/auth';

export default defineNuxtPlugin((nuxtApp) => {
    const authStore = useAuthStore();
    const config = useRuntimeConfig();

    const client = createClient({
        url: config.public.apiUrl,
        requestPolicy: 'cache-and-network',
        exchanges: [
            cacheExchange,
            mapExchange({
                onOperation(operation) {
                    const token = Cookies.get<AuthCookie | null>('auth_data')?.accessToken;
                    if (!token) {
                        console.error('No auth token found for operation:', operation);
                        return;
                    }
                    
                    if (
                        ['query', 'mutation'].includes(operation.kind) &&
                        operation.variables?.loaderId
                    ) {
                        updateLoaderState(
                            operation.variables.loaderId,
                            XHR_REQUEST_STATES.PROGRESS,
                        );
                    }
                },
                onResult(result) {
                    const loaderId = result.operation.variables?.loaderId;
                    if (result.data && loaderId) {
                        updateLoaderState(
                            loaderId,
                            XHR_REQUEST_STATES.SUCCESS,
                            result.data,
                        );
                    }
                    if (result.error) {
                        result.error.message = result.error.message.replace(
                            '[GraphQL] ',
                            '',
                        );
                        
                        // Handle auth errors
                        if (
                            [
                                'Token has expired',
                                'Auth token expired',
                                'User authentication required.',
                                'Invalid token',
                                'Authorization header missing'
                            ].includes(result.error.message)
                        ) {
                            console.error('Auth error:', result.error.message);
                            authStore.logout();
                            return;
                        }

                        updateLoaderState(loaderId, XHR_REQUEST_STATES.ERROR, {
                            error: result.error,
                        });
                    }
                },
            }),
            fetchExchange,
        ],
        fetchOptions: () => {
            const token = Cookies.get<AuthCookie | null>('auth_data')?.accessToken;
            if (!token) {
                console.error('No auth token found in fetchOptions');
            }
            return {
                headers: { Authorization: token ? `Bearer ${token}` : '' },
            };
        },
    });

    const updateLoaderState = (
        loaderId: string,
        loaderState: string,
        response?: unknown,
    ) => {
        const httpLoaderStore = useHttpLoaderStore();
        if (loaderId) {
            httpLoaderStore.update(loaderId, loaderState, response);
        }
    };

    nuxtApp.vueApp.use(urql, {
        url: config.public.apiUrl,
        exchanges: [cacheExchange, fetchExchange],
        fetchOptions: () => {
            const token = Cookies.get<AuthCookie | null>('auth_data')
                ?.accessToken;
            return {
                headers: { Authorization: token ? `Bearer ${token}` : '' },
            };
        },
    });

    return {
        provide: {
            urql: client,
        },
    };
});
