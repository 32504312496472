import { Capacitor } from '@capacitor/core'

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client && Capacitor.isPluginAvailable('App')) {
    const { App } = Capacitor.Plugins

    nuxtApp.hook('app:mounted', () => {
      App.addListener('appUrlOpen', (event: { url: string }) => {
        const slug = event.url.split('.com').pop()
        if (slug) {
          nuxtApp.$router.push(slug)
        }
      })
    })
  }
}) 