import { default as old_45starthRN3ZjyHq6Meta } from "/codebuild/output/src179013634/src/frontend/pages/_archive/old-start.vue?macro=true";
import { default as _91_46_46_46artist_id_93KWEzqr1bGfMeta } from "/codebuild/output/src179013634/src/frontend/pages/artist/[...artist_id].vue?macro=true";
import { default as index1GycE3hXdeMeta } from "/codebuild/output/src179013634/src/frontend/pages/artist/index.vue?macro=true";
import { default as _91video_slug_936SwEY8ElPUMeta } from "/codebuild/output/src179013634/src/frontend/pages/auto/[video_slug].vue?macro=true";
import { default as indexeNAw0WuEAUMeta } from "/codebuild/output/src179013634/src/frontend/pages/auto/index.vue?macro=true";
import { default as _91artist_slug_93AhQ2mNhpRLMeta } from "/codebuild/output/src179013634/src/frontend/pages/bio/[artist_slug].vue?macro=true";
import { default as _91video_slug_937HAOpbHWyFMeta } from "/codebuild/output/src179013634/src/frontend/pages/browse/[video_slug].vue?macro=true";
import { default as indexNVZ8IIM7JwMeta } from "/codebuild/output/src179013634/src/frontend/pages/browse/index.vue?macro=true";
import { default as indexs3tTd05vKbMeta } from "/codebuild/output/src179013634/src/frontend/pages/cancel/index.vue?macro=true";
import { default as _91id_939KGWlyOEfRMeta } from "/codebuild/output/src179013634/src/frontend/pages/category/[id].vue?macro=true";
import { default as indexX1bRHmWhPRMeta } from "/codebuild/output/src179013634/src/frontend/pages/cms/index.vue?macro=true";
import { default as indexWz4bfpSPUSMeta } from "/codebuild/output/src179013634/src/frontend/pages/cms/player-test/index.vue?macro=true";
import { default as codeHPeSRCXw6RMeta } from "/codebuild/output/src179013634/src/frontend/pages/code.vue?macro=true";
import { default as _91id_93yVfhrasU4fMeta } from "/codebuild/output/src179013634/src/frontend/pages/collections/[id].vue?macro=true";
import { default as connectedq9aGWx6IaLMeta } from "/codebuild/output/src179013634/src/frontend/pages/collections/connected.vue?macro=true";
import { default as index3CSNaxIoO9Meta } from "/codebuild/output/src179013634/src/frontend/pages/collections/index.vue?macro=true";
import { default as myMqHtICpJDNMeta } from "/codebuild/output/src179013634/src/frontend/pages/collections/my.vue?macro=true";
import { default as continueQlMUQHtorTMeta } from "/codebuild/output/src179013634/src/frontend/pages/continue.vue?macro=true";
import { default as new_45payment_45methodQXaMvSBMDYMeta } from "/codebuild/output/src179013634/src/frontend/pages/failure/new-payment-method.vue?macro=true";
import { default as indexkjoGrkNNJPMeta } from "/codebuild/output/src179013634/src/frontend/pages/ghost/index.vue?macro=true";
import { default as index3LL7fzI2e5Meta } from "/codebuild/output/src179013634/src/frontend/pages/index.vue?macro=true";
import { default as loginvH5PgavDitMeta } from "/codebuild/output/src179013634/src/frontend/pages/login.vue?macro=true";
import { default as mobile_45appTugBT35HW9Meta } from "/codebuild/output/src179013634/src/frontend/pages/mobile-app.vue?macro=true";
import { default as indexjLAa3Yn8UYMeta } from "/codebuild/output/src179013634/src/frontend/pages/my-account/index.vue?macro=true";
import { default as qzFlowIJOkTpzdkvMeta } from "/codebuild/output/src179013634/src/frontend/pages/onboard/qzFlow.vue?macro=true";
import { default as onboarding3Lt7aZz6AcMeta } from "/codebuild/output/src179013634/src/frontend/pages/onboarding.vue?macro=true";
import { default as playlistbc0o7FZla2Meta } from "/codebuild/output/src179013634/src/frontend/pages/playlist.vue?macro=true";
import { default as questionsGpOEk5XOY2Meta } from "/codebuild/output/src179013634/src/frontend/pages/questions.vue?macro=true";
import { default as _91token_93SlGlJd6LSmMeta } from "/codebuild/output/src179013634/src/frontend/pages/reset-password/[token].vue?macro=true";
import { default as indexDWGLDv3QtQMeta } from "/codebuild/output/src179013634/src/frontend/pages/reset-password/index.vue?macro=true";
import { default as indexSbUwtBim1cMeta } from "/codebuild/output/src179013634/src/frontend/pages/search/index.vue?macro=true";
import { default as select8XzNSma4RIMeta } from "/codebuild/output/src179013634/src/frontend/pages/select.vue?macro=true";
import { default as a_45_91artistSlug_93NTQbMPKbE4Meta } from "/codebuild/output/src179013634/src/frontend/pages/share/a-[artistSlug].vue?macro=true";
import { default as c_45_91collectionId_93tiJdXLdGecMeta } from "/codebuild/output/src179013634/src/frontend/pages/share/c-[collectionId].vue?macro=true";
import { default as v_45_91videoSlug_93qAOlLBgyloMeta } from "/codebuild/output/src179013634/src/frontend/pages/share/v-[videoSlug].vue?macro=true";
import { default as index2kTPW6HLQzMeta } from "/codebuild/output/src179013634/src/frontend/pages/signup/base-club/index.vue?macro=true";
import { default as planslsLzCquU2wMeta } from "/codebuild/output/src179013634/src/frontend/pages/signup/base-club/plans.vue?macro=true";
import { default as indexbimXklA8D9Meta } from "/codebuild/output/src179013634/src/frontend/pages/signup/index.vue?macro=true";
import { default as plansQavWzapeH2Meta } from "/codebuild/output/src179013634/src/frontend/pages/signup/plans.vue?macro=true";
import { default as indexyafHDIAvhmMeta } from "/codebuild/output/src179013634/src/frontend/pages/signup/promo/index.vue?macro=true";
import { default as index8F2zVn1PvjMeta } from "/codebuild/output/src179013634/src/frontend/pages/signup/select/index.vue?macro=true";
import { default as plansrd2d5NPxu0Meta } from "/codebuild/output/src179013634/src/frontend/pages/signup/select/plans.vue?macro=true";
import { default as startHoKdkQvJZTMeta } from "/codebuild/output/src179013634/src/frontend/pages/start.vue?macro=true";
import { default as indexg5XfOzB175Meta } from "/codebuild/output/src179013634/src/frontend/pages/success/index.vue?macro=true";
import { default as new_45payment_45methodmdRnxvDMGXMeta } from "/codebuild/output/src179013634/src/frontend/pages/success/new-payment-method.vue?macro=true";
import { default as terms_of_useV3Ivm0OUqPMeta } from "/codebuild/output/src179013634/src/frontend/pages/terms_of_use.vue?macro=true";
export default [
  {
    name: old_45starthRN3ZjyHq6Meta?.name ?? "_archive-old-start",
    path: old_45starthRN3ZjyHq6Meta?.path ?? "/_archive/old-start",
    meta: old_45starthRN3ZjyHq6Meta || {},
    alias: old_45starthRN3ZjyHq6Meta?.alias || [],
    redirect: old_45starthRN3ZjyHq6Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/_archive/old-start.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46artist_id_93KWEzqr1bGfMeta?.name ?? "artist-artist_id",
    path: _91_46_46_46artist_id_93KWEzqr1bGfMeta?.path ?? "/artist/:artist_id(.*)*",
    meta: _91_46_46_46artist_id_93KWEzqr1bGfMeta || {},
    alias: _91_46_46_46artist_id_93KWEzqr1bGfMeta?.alias || [],
    redirect: _91_46_46_46artist_id_93KWEzqr1bGfMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/artist/[...artist_id].vue").then(m => m.default || m)
  },
  {
    name: index1GycE3hXdeMeta?.name ?? "artist",
    path: index1GycE3hXdeMeta?.path ?? "/artist",
    meta: index1GycE3hXdeMeta || {},
    alias: index1GycE3hXdeMeta?.alias || [],
    redirect: index1GycE3hXdeMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/artist/index.vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_936SwEY8ElPUMeta?.name ?? "auto-video_slug",
    path: _91video_slug_936SwEY8ElPUMeta?.path ?? "/auto/:video_slug()",
    meta: _91video_slug_936SwEY8ElPUMeta || {},
    alias: _91video_slug_936SwEY8ElPUMeta?.alias || [],
    redirect: _91video_slug_936SwEY8ElPUMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/auto/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexeNAw0WuEAUMeta?.name ?? "auto",
    path: indexeNAw0WuEAUMeta?.path ?? "/auto",
    meta: indexeNAw0WuEAUMeta || {},
    alias: indexeNAw0WuEAUMeta?.alias || [],
    redirect: indexeNAw0WuEAUMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/auto/index.vue").then(m => m.default || m)
  },
  {
    name: _91artist_slug_93AhQ2mNhpRLMeta?.name ?? "bio-artist_slug",
    path: _91artist_slug_93AhQ2mNhpRLMeta?.path ?? "/bio/:artist_slug()",
    meta: _91artist_slug_93AhQ2mNhpRLMeta || {},
    alias: _91artist_slug_93AhQ2mNhpRLMeta?.alias || [],
    redirect: _91artist_slug_93AhQ2mNhpRLMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/bio/[artist_slug].vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_937HAOpbHWyFMeta?.name ?? "browse-video_slug",
    path: _91video_slug_937HAOpbHWyFMeta?.path ?? "/browse/:video_slug()",
    meta: _91video_slug_937HAOpbHWyFMeta || {},
    alias: _91video_slug_937HAOpbHWyFMeta?.alias || [],
    redirect: _91video_slug_937HAOpbHWyFMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/browse/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexNVZ8IIM7JwMeta?.name ?? "browse",
    path: indexNVZ8IIM7JwMeta?.path ?? "/browse",
    meta: indexNVZ8IIM7JwMeta || {},
    alias: indexNVZ8IIM7JwMeta?.alias || [],
    redirect: indexNVZ8IIM7JwMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3tTd05vKbMeta?.name ?? "cancel",
    path: indexs3tTd05vKbMeta?.path ?? "/cancel",
    meta: indexs3tTd05vKbMeta || {},
    alias: indexs3tTd05vKbMeta?.alias || [],
    redirect: indexs3tTd05vKbMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939KGWlyOEfRMeta?.name ?? "category-id",
    path: _91id_939KGWlyOEfRMeta?.path ?? "/category/:id()",
    meta: _91id_939KGWlyOEfRMeta || {},
    alias: _91id_939KGWlyOEfRMeta?.alias || [],
    redirect: _91id_939KGWlyOEfRMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: indexX1bRHmWhPRMeta?.name ?? "cms",
    path: indexX1bRHmWhPRMeta?.path ?? "/cms",
    meta: indexX1bRHmWhPRMeta || {},
    alias: indexX1bRHmWhPRMeta?.alias || [],
    redirect: indexX1bRHmWhPRMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/cms/index.vue").then(m => m.default || m)
  },
  {
    name: indexWz4bfpSPUSMeta?.name ?? "cms-player-test",
    path: indexWz4bfpSPUSMeta?.path ?? "/cms/player-test",
    meta: indexWz4bfpSPUSMeta || {},
    alias: indexWz4bfpSPUSMeta?.alias || [],
    redirect: indexWz4bfpSPUSMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/cms/player-test/index.vue").then(m => m.default || m)
  },
  {
    name: codeHPeSRCXw6RMeta?.name ?? "code",
    path: codeHPeSRCXw6RMeta?.path ?? "/code",
    meta: codeHPeSRCXw6RMeta || {},
    alias: codeHPeSRCXw6RMeta?.alias || [],
    redirect: codeHPeSRCXw6RMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/code.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yVfhrasU4fMeta?.name ?? "collections-id",
    path: _91id_93yVfhrasU4fMeta?.path ?? "/collections/:id()",
    meta: _91id_93yVfhrasU4fMeta || {},
    alias: _91id_93yVfhrasU4fMeta?.alias || [],
    redirect: _91id_93yVfhrasU4fMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: connectedq9aGWx6IaLMeta?.name ?? "collections-connected",
    path: connectedq9aGWx6IaLMeta?.path ?? "/collections/connected",
    meta: connectedq9aGWx6IaLMeta || {},
    alias: connectedq9aGWx6IaLMeta?.alias || [],
    redirect: connectedq9aGWx6IaLMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/collections/connected.vue").then(m => m.default || m)
  },
  {
    name: index3CSNaxIoO9Meta?.name ?? "collections",
    path: index3CSNaxIoO9Meta?.path ?? "/collections",
    meta: index3CSNaxIoO9Meta || {},
    alias: index3CSNaxIoO9Meta?.alias || [],
    redirect: index3CSNaxIoO9Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: myMqHtICpJDNMeta?.name ?? "collections-my",
    path: myMqHtICpJDNMeta?.path ?? "/collections/my",
    meta: myMqHtICpJDNMeta || {},
    alias: myMqHtICpJDNMeta?.alias || [],
    redirect: myMqHtICpJDNMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/collections/my.vue").then(m => m.default || m)
  },
  {
    name: continueQlMUQHtorTMeta?.name ?? "continue",
    path: continueQlMUQHtorTMeta?.path ?? "/continue",
    meta: continueQlMUQHtorTMeta || {},
    alias: continueQlMUQHtorTMeta?.alias || [],
    redirect: continueQlMUQHtorTMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/continue.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodQXaMvSBMDYMeta?.name ?? "failure-new-payment-method",
    path: new_45payment_45methodQXaMvSBMDYMeta?.path ?? "/failure/new-payment-method",
    meta: new_45payment_45methodQXaMvSBMDYMeta || {},
    alias: new_45payment_45methodQXaMvSBMDYMeta?.alias || [],
    redirect: new_45payment_45methodQXaMvSBMDYMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/failure/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: indexkjoGrkNNJPMeta?.name ?? "ghost",
    path: indexkjoGrkNNJPMeta?.path ?? "/ghost",
    meta: indexkjoGrkNNJPMeta || {},
    alias: indexkjoGrkNNJPMeta?.alias || [],
    redirect: indexkjoGrkNNJPMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/ghost/index.vue").then(m => m.default || m)
  },
  {
    name: index3LL7fzI2e5Meta?.name ?? "index",
    path: index3LL7fzI2e5Meta?.path ?? "/",
    meta: index3LL7fzI2e5Meta || {},
    alias: index3LL7fzI2e5Meta?.alias || [],
    redirect: index3LL7fzI2e5Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginvH5PgavDitMeta?.name ?? "login",
    path: loginvH5PgavDitMeta?.path ?? "/login",
    meta: loginvH5PgavDitMeta || {},
    alias: loginvH5PgavDitMeta?.alias || [],
    redirect: loginvH5PgavDitMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mobile_45appTugBT35HW9Meta?.name ?? "mobile-app",
    path: mobile_45appTugBT35HW9Meta?.path ?? "/mobile-app",
    meta: mobile_45appTugBT35HW9Meta || {},
    alias: mobile_45appTugBT35HW9Meta?.alias || [],
    redirect: mobile_45appTugBT35HW9Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: indexjLAa3Yn8UYMeta?.name ?? "my-account",
    path: indexjLAa3Yn8UYMeta?.path ?? "/my-account",
    meta: indexjLAa3Yn8UYMeta || {},
    alias: indexjLAa3Yn8UYMeta?.alias || [],
    redirect: indexjLAa3Yn8UYMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: qzFlowIJOkTpzdkvMeta?.name ?? "onboard-qzFlow",
    path: qzFlowIJOkTpzdkvMeta?.path ?? "/onboard/qzFlow",
    meta: qzFlowIJOkTpzdkvMeta || {},
    alias: qzFlowIJOkTpzdkvMeta?.alias || [],
    redirect: qzFlowIJOkTpzdkvMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/onboard/qzFlow.vue").then(m => m.default || m)
  },
  {
    name: onboarding3Lt7aZz6AcMeta?.name ?? "onboarding",
    path: onboarding3Lt7aZz6AcMeta?.path ?? "/onboarding",
    meta: onboarding3Lt7aZz6AcMeta || {},
    alias: onboarding3Lt7aZz6AcMeta?.alias || [],
    redirect: onboarding3Lt7aZz6AcMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: playlistbc0o7FZla2Meta?.name ?? "playlist",
    path: playlistbc0o7FZla2Meta?.path ?? "/playlist",
    meta: playlistbc0o7FZla2Meta || {},
    alias: playlistbc0o7FZla2Meta?.alias || [],
    redirect: playlistbc0o7FZla2Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/playlist.vue").then(m => m.default || m)
  },
  {
    name: questionsGpOEk5XOY2Meta?.name ?? "questions",
    path: questionsGpOEk5XOY2Meta?.path ?? "/questions",
    meta: questionsGpOEk5XOY2Meta || {},
    alias: questionsGpOEk5XOY2Meta?.alias || [],
    redirect: questionsGpOEk5XOY2Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: _91token_93SlGlJd6LSmMeta?.name ?? "reset-password-token",
    path: _91token_93SlGlJd6LSmMeta?.path ?? "/reset-password/:token()",
    meta: _91token_93SlGlJd6LSmMeta || {},
    alias: _91token_93SlGlJd6LSmMeta?.alias || [],
    redirect: _91token_93SlGlJd6LSmMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexDWGLDv3QtQMeta?.name ?? "reset-password",
    path: indexDWGLDv3QtQMeta?.path ?? "/reset-password",
    meta: indexDWGLDv3QtQMeta || {},
    alias: indexDWGLDv3QtQMeta?.alias || [],
    redirect: indexDWGLDv3QtQMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexSbUwtBim1cMeta?.name ?? "search",
    path: indexSbUwtBim1cMeta?.path ?? "/search",
    meta: indexSbUwtBim1cMeta || {},
    alias: indexSbUwtBim1cMeta?.alias || [],
    redirect: indexSbUwtBim1cMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: select8XzNSma4RIMeta?.name ?? "select",
    path: select8XzNSma4RIMeta?.path ?? "/select",
    meta: select8XzNSma4RIMeta || {},
    alias: select8XzNSma4RIMeta?.alias || [],
    redirect: select8XzNSma4RIMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/select.vue").then(m => m.default || m)
  },
  {
    name: a_45_91artistSlug_93NTQbMPKbE4Meta?.name ?? "share-a-artistSlug",
    path: a_45_91artistSlug_93NTQbMPKbE4Meta?.path ?? "/share/a-:artistSlug()",
    meta: a_45_91artistSlug_93NTQbMPKbE4Meta || {},
    alias: a_45_91artistSlug_93NTQbMPKbE4Meta?.alias || [],
    redirect: a_45_91artistSlug_93NTQbMPKbE4Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/share/a-[artistSlug].vue").then(m => m.default || m)
  },
  {
    name: c_45_91collectionId_93tiJdXLdGecMeta?.name ?? "share-c-collectionId",
    path: c_45_91collectionId_93tiJdXLdGecMeta?.path ?? "/share/c-:collectionId()",
    meta: c_45_91collectionId_93tiJdXLdGecMeta || {},
    alias: c_45_91collectionId_93tiJdXLdGecMeta?.alias || [],
    redirect: c_45_91collectionId_93tiJdXLdGecMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/share/c-[collectionId].vue").then(m => m.default || m)
  },
  {
    name: v_45_91videoSlug_93qAOlLBgyloMeta?.name ?? "share-v-videoSlug",
    path: v_45_91videoSlug_93qAOlLBgyloMeta?.path ?? "/share/v-:videoSlug()",
    meta: v_45_91videoSlug_93qAOlLBgyloMeta || {},
    alias: v_45_91videoSlug_93qAOlLBgyloMeta?.alias || [],
    redirect: v_45_91videoSlug_93qAOlLBgyloMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/share/v-[videoSlug].vue").then(m => m.default || m)
  },
  {
    name: index2kTPW6HLQzMeta?.name ?? "signup-base-club",
    path: index2kTPW6HLQzMeta?.path ?? "/signup/base-club",
    meta: index2kTPW6HLQzMeta || {},
    alias: index2kTPW6HLQzMeta?.alias || [],
    redirect: index2kTPW6HLQzMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/base-club/index.vue").then(m => m.default || m)
  },
  {
    name: planslsLzCquU2wMeta?.name ?? "signup-base-club-plans",
    path: planslsLzCquU2wMeta?.path ?? "/signup/base-club/plans",
    meta: planslsLzCquU2wMeta || {},
    alias: planslsLzCquU2wMeta?.alias || [],
    redirect: planslsLzCquU2wMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/base-club/plans.vue").then(m => m.default || m)
  },
  {
    name: indexbimXklA8D9Meta?.name ?? "signup",
    path: indexbimXklA8D9Meta?.path ?? "/signup",
    meta: indexbimXklA8D9Meta || {},
    alias: indexbimXklA8D9Meta?.alias || [],
    redirect: indexbimXklA8D9Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: plansQavWzapeH2Meta?.name ?? "signup-plans",
    path: plansQavWzapeH2Meta?.path ?? "/signup/plans",
    meta: plansQavWzapeH2Meta || {},
    alias: plansQavWzapeH2Meta?.alias || [],
    redirect: plansQavWzapeH2Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/plans.vue").then(m => m.default || m)
  },
  {
    name: indexyafHDIAvhmMeta?.name ?? "signup-promo",
    path: indexyafHDIAvhmMeta?.path ?? "/signup/promo",
    meta: indexyafHDIAvhmMeta || {},
    alias: indexyafHDIAvhmMeta?.alias || [],
    redirect: indexyafHDIAvhmMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index8F2zVn1PvjMeta?.name ?? "signup-select",
    path: index8F2zVn1PvjMeta?.path ?? "/signup/select",
    meta: index8F2zVn1PvjMeta || {},
    alias: index8F2zVn1PvjMeta?.alias || [],
    redirect: index8F2zVn1PvjMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/select/index.vue").then(m => m.default || m)
  },
  {
    name: plansrd2d5NPxu0Meta?.name ?? "signup-select-plans",
    path: plansrd2d5NPxu0Meta?.path ?? "/signup/select/plans",
    meta: plansrd2d5NPxu0Meta || {},
    alias: plansrd2d5NPxu0Meta?.alias || [],
    redirect: plansrd2d5NPxu0Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/signup/select/plans.vue").then(m => m.default || m)
  },
  {
    name: startHoKdkQvJZTMeta?.name ?? "start",
    path: startHoKdkQvJZTMeta?.path ?? "/start",
    meta: startHoKdkQvJZTMeta || {},
    alias: startHoKdkQvJZTMeta?.alias || [],
    redirect: startHoKdkQvJZTMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/start.vue").then(m => m.default || m)
  },
  {
    name: indexg5XfOzB175Meta?.name ?? "success",
    path: indexg5XfOzB175Meta?.path ?? "/success",
    meta: indexg5XfOzB175Meta || {},
    alias: indexg5XfOzB175Meta?.alias || [],
    redirect: indexg5XfOzB175Meta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodmdRnxvDMGXMeta?.name ?? "success-new-payment-method",
    path: new_45payment_45methodmdRnxvDMGXMeta?.path ?? "/success/new-payment-method",
    meta: new_45payment_45methodmdRnxvDMGXMeta || {},
    alias: new_45payment_45methodmdRnxvDMGXMeta?.alias || [],
    redirect: new_45payment_45methodmdRnxvDMGXMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/success/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: terms_of_useV3Ivm0OUqPMeta?.name ?? "terms_of_use",
    path: terms_of_useV3Ivm0OUqPMeta?.path ?? "/terms_of_use",
    meta: terms_of_useV3Ivm0OUqPMeta || {},
    alias: terms_of_useV3Ivm0OUqPMeta?.alias || [],
    redirect: terms_of_useV3Ivm0OUqPMeta?.redirect,
    component: () => import("/codebuild/output/src179013634/src/frontend/pages/terms_of_use.vue").then(m => m.default || m)
  }
]