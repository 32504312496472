
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "76d78e1e-f4bd-43ff-bba2-54f752ed06de"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/codebuild/output/src3606977093/src/frontend/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
