import { default as old_45start2kR1rXyJeLMeta } from "/codebuild/output/src3606977093/src/frontend/pages/_archive/old-start.vue?macro=true";
import { default as _91_46_46_46artist_id_93YluZMDdSIyMeta } from "/codebuild/output/src3606977093/src/frontend/pages/artist/[...artist_id].vue?macro=true";
import { default as index5D3jg15iadMeta } from "/codebuild/output/src3606977093/src/frontend/pages/artist/index.vue?macro=true";
import { default as _91video_slug_93guPauO8AL1Meta } from "/codebuild/output/src3606977093/src/frontend/pages/auto/[video_slug].vue?macro=true";
import { default as indexoOGW1fJYVhMeta } from "/codebuild/output/src3606977093/src/frontend/pages/auto/index.vue?macro=true";
import { default as _91artist_slug_93M3ZWh2odiTMeta } from "/codebuild/output/src3606977093/src/frontend/pages/bio/[artist_slug].vue?macro=true";
import { default as _91video_slug_933qlwe0KnSZMeta } from "/codebuild/output/src3606977093/src/frontend/pages/browse/[video_slug].vue?macro=true";
import { default as indexbYQPp4Qr48Meta } from "/codebuild/output/src3606977093/src/frontend/pages/browse/index.vue?macro=true";
import { default as indexED0cnkNZGVMeta } from "/codebuild/output/src3606977093/src/frontend/pages/cancel/index.vue?macro=true";
import { default as _91id_93e5rlBdlVlXMeta } from "/codebuild/output/src3606977093/src/frontend/pages/category/[id].vue?macro=true";
import { default as index2UZVQAoTnLMeta } from "/codebuild/output/src3606977093/src/frontend/pages/cms/index.vue?macro=true";
import { default as codeYJhHPUOmKJMeta } from "/codebuild/output/src3606977093/src/frontend/pages/code.vue?macro=true";
import { default as _91id_93NKHIF909P9Meta } from "/codebuild/output/src3606977093/src/frontend/pages/collections/[id].vue?macro=true";
import { default as connectedQUgcKywydUMeta } from "/codebuild/output/src3606977093/src/frontend/pages/collections/connected.vue?macro=true";
import { default as indexo0VgXU2gLSMeta } from "/codebuild/output/src3606977093/src/frontend/pages/collections/index.vue?macro=true";
import { default as mydvfgxbogJHMeta } from "/codebuild/output/src3606977093/src/frontend/pages/collections/my.vue?macro=true";
import { default as continueZr0giw3VQqMeta } from "/codebuild/output/src3606977093/src/frontend/pages/continue.vue?macro=true";
import { default as new_45payment_45methodgPgSLSrKI1Meta } from "/codebuild/output/src3606977093/src/frontend/pages/failure/new-payment-method.vue?macro=true";
import { default as indexEdl6pJ8sQCMeta } from "/codebuild/output/src3606977093/src/frontend/pages/ghost/index.vue?macro=true";
import { default as indexKrfTBlwqV8Meta } from "/codebuild/output/src3606977093/src/frontend/pages/index.vue?macro=true";
import { default as loginTmnLwEeZLsMeta } from "/codebuild/output/src3606977093/src/frontend/pages/login.vue?macro=true";
import { default as mobile_45appwbRpFqTO7OMeta } from "/codebuild/output/src3606977093/src/frontend/pages/mobile-app.vue?macro=true";
import { default as indexz4Lt1eiXHAMeta } from "/codebuild/output/src3606977093/src/frontend/pages/my-account/index.vue?macro=true";
import { default as qzFlowB78uKsqEhWMeta } from "/codebuild/output/src3606977093/src/frontend/pages/onboard/qzFlow.vue?macro=true";
import { default as onboardingG4vPkiHtCLMeta } from "/codebuild/output/src3606977093/src/frontend/pages/onboarding.vue?macro=true";
import { default as player_45test8bpnBwREpeMeta } from "/codebuild/output/src3606977093/src/frontend/pages/player-test.vue?macro=true";
import { default as playlistknTQglgezfMeta } from "/codebuild/output/src3606977093/src/frontend/pages/playlist.vue?macro=true";
import { default as questionsUKxSB8wDWYMeta } from "/codebuild/output/src3606977093/src/frontend/pages/questions.vue?macro=true";
import { default as _91token_93lUvSAm01OAMeta } from "/codebuild/output/src3606977093/src/frontend/pages/reset-password/[token].vue?macro=true";
import { default as indexBy0XpC5nIsMeta } from "/codebuild/output/src3606977093/src/frontend/pages/reset-password/index.vue?macro=true";
import { default as indexku8Usy48mCMeta } from "/codebuild/output/src3606977093/src/frontend/pages/search/index.vue?macro=true";
import { default as selectErbur3FmKrMeta } from "/codebuild/output/src3606977093/src/frontend/pages/select.vue?macro=true";
import { default as a_45_91artistSlug_93hYnXsJCoJSMeta } from "/codebuild/output/src3606977093/src/frontend/pages/share/a-[artistSlug].vue?macro=true";
import { default as c_45_91collectionId_93nxkc8ftGMxMeta } from "/codebuild/output/src3606977093/src/frontend/pages/share/c-[collectionId].vue?macro=true";
import { default as v_45_91videoSlug_93yKF3LrXUm8Meta } from "/codebuild/output/src3606977093/src/frontend/pages/share/v-[videoSlug].vue?macro=true";
import { default as indexqahUT167iUMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/base-club/index.vue?macro=true";
import { default as plans6LHeg4T8SQMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/base-club/plans.vue?macro=true";
import { default as indexkLAxT9XkQxMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/index.vue?macro=true";
import { default as plansWpBSZLFN1AMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/plans.vue?macro=true";
import { default as indexOgcBEW2f8BMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/promo/index.vue?macro=true";
import { default as indexh3MoGDnZhIMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/select/index.vue?macro=true";
import { default as plans0BzvjAhVOpMeta } from "/codebuild/output/src3606977093/src/frontend/pages/signup/select/plans.vue?macro=true";
import { default as start7FvBlKZOU2Meta } from "/codebuild/output/src3606977093/src/frontend/pages/start.vue?macro=true";
import { default as indexF3W1kWEAszMeta } from "/codebuild/output/src3606977093/src/frontend/pages/success/index.vue?macro=true";
import { default as new_45payment_45methodYKgrP8iRwKMeta } from "/codebuild/output/src3606977093/src/frontend/pages/success/new-payment-method.vue?macro=true";
import { default as terms_of_useN0VVkk3T6SMeta } from "/codebuild/output/src3606977093/src/frontend/pages/terms_of_use.vue?macro=true";
export default [
  {
    name: old_45start2kR1rXyJeLMeta?.name ?? "_archive-old-start",
    path: old_45start2kR1rXyJeLMeta?.path ?? "/_archive/old-start",
    meta: old_45start2kR1rXyJeLMeta || {},
    alias: old_45start2kR1rXyJeLMeta?.alias || [],
    redirect: old_45start2kR1rXyJeLMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/_archive/old-start.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46artist_id_93YluZMDdSIyMeta?.name ?? "artist-artist_id",
    path: _91_46_46_46artist_id_93YluZMDdSIyMeta?.path ?? "/artist/:artist_id(.*)*",
    meta: _91_46_46_46artist_id_93YluZMDdSIyMeta || {},
    alias: _91_46_46_46artist_id_93YluZMDdSIyMeta?.alias || [],
    redirect: _91_46_46_46artist_id_93YluZMDdSIyMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/artist/[...artist_id].vue").then(m => m.default || m)
  },
  {
    name: index5D3jg15iadMeta?.name ?? "artist",
    path: index5D3jg15iadMeta?.path ?? "/artist",
    meta: index5D3jg15iadMeta || {},
    alias: index5D3jg15iadMeta?.alias || [],
    redirect: index5D3jg15iadMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/artist/index.vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_93guPauO8AL1Meta?.name ?? "auto-video_slug",
    path: _91video_slug_93guPauO8AL1Meta?.path ?? "/auto/:video_slug()",
    meta: _91video_slug_93guPauO8AL1Meta || {},
    alias: _91video_slug_93guPauO8AL1Meta?.alias || [],
    redirect: _91video_slug_93guPauO8AL1Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/auto/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexoOGW1fJYVhMeta?.name ?? "auto",
    path: indexoOGW1fJYVhMeta?.path ?? "/auto",
    meta: indexoOGW1fJYVhMeta || {},
    alias: indexoOGW1fJYVhMeta?.alias || [],
    redirect: indexoOGW1fJYVhMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/auto/index.vue").then(m => m.default || m)
  },
  {
    name: _91artist_slug_93M3ZWh2odiTMeta?.name ?? "bio-artist_slug",
    path: _91artist_slug_93M3ZWh2odiTMeta?.path ?? "/bio/:artist_slug()",
    meta: _91artist_slug_93M3ZWh2odiTMeta || {},
    alias: _91artist_slug_93M3ZWh2odiTMeta?.alias || [],
    redirect: _91artist_slug_93M3ZWh2odiTMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/bio/[artist_slug].vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_933qlwe0KnSZMeta?.name ?? "browse-video_slug",
    path: _91video_slug_933qlwe0KnSZMeta?.path ?? "/browse/:video_slug()",
    meta: _91video_slug_933qlwe0KnSZMeta || {},
    alias: _91video_slug_933qlwe0KnSZMeta?.alias || [],
    redirect: _91video_slug_933qlwe0KnSZMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/browse/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexbYQPp4Qr48Meta?.name ?? "browse",
    path: indexbYQPp4Qr48Meta?.path ?? "/browse",
    meta: indexbYQPp4Qr48Meta || {},
    alias: indexbYQPp4Qr48Meta?.alias || [],
    redirect: indexbYQPp4Qr48Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexED0cnkNZGVMeta?.name ?? "cancel",
    path: indexED0cnkNZGVMeta?.path ?? "/cancel",
    meta: indexED0cnkNZGVMeta || {},
    alias: indexED0cnkNZGVMeta?.alias || [],
    redirect: indexED0cnkNZGVMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93e5rlBdlVlXMeta?.name ?? "category-id",
    path: _91id_93e5rlBdlVlXMeta?.path ?? "/category/:id()",
    meta: _91id_93e5rlBdlVlXMeta || {},
    alias: _91id_93e5rlBdlVlXMeta?.alias || [],
    redirect: _91id_93e5rlBdlVlXMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: index2UZVQAoTnLMeta?.name ?? "cms",
    path: index2UZVQAoTnLMeta?.path ?? "/cms",
    meta: index2UZVQAoTnLMeta || {},
    alias: index2UZVQAoTnLMeta?.alias || [],
    redirect: index2UZVQAoTnLMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/cms/index.vue").then(m => m.default || m)
  },
  {
    name: codeYJhHPUOmKJMeta?.name ?? "code",
    path: codeYJhHPUOmKJMeta?.path ?? "/code",
    meta: codeYJhHPUOmKJMeta || {},
    alias: codeYJhHPUOmKJMeta?.alias || [],
    redirect: codeYJhHPUOmKJMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/code.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NKHIF909P9Meta?.name ?? "collections-id",
    path: _91id_93NKHIF909P9Meta?.path ?? "/collections/:id()",
    meta: _91id_93NKHIF909P9Meta || {},
    alias: _91id_93NKHIF909P9Meta?.alias || [],
    redirect: _91id_93NKHIF909P9Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: connectedQUgcKywydUMeta?.name ?? "collections-connected",
    path: connectedQUgcKywydUMeta?.path ?? "/collections/connected",
    meta: connectedQUgcKywydUMeta || {},
    alias: connectedQUgcKywydUMeta?.alias || [],
    redirect: connectedQUgcKywydUMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/collections/connected.vue").then(m => m.default || m)
  },
  {
    name: indexo0VgXU2gLSMeta?.name ?? "collections",
    path: indexo0VgXU2gLSMeta?.path ?? "/collections",
    meta: indexo0VgXU2gLSMeta || {},
    alias: indexo0VgXU2gLSMeta?.alias || [],
    redirect: indexo0VgXU2gLSMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: mydvfgxbogJHMeta?.name ?? "collections-my",
    path: mydvfgxbogJHMeta?.path ?? "/collections/my",
    meta: mydvfgxbogJHMeta || {},
    alias: mydvfgxbogJHMeta?.alias || [],
    redirect: mydvfgxbogJHMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/collections/my.vue").then(m => m.default || m)
  },
  {
    name: continueZr0giw3VQqMeta?.name ?? "continue",
    path: continueZr0giw3VQqMeta?.path ?? "/continue",
    meta: continueZr0giw3VQqMeta || {},
    alias: continueZr0giw3VQqMeta?.alias || [],
    redirect: continueZr0giw3VQqMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/continue.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodgPgSLSrKI1Meta?.name ?? "failure-new-payment-method",
    path: new_45payment_45methodgPgSLSrKI1Meta?.path ?? "/failure/new-payment-method",
    meta: new_45payment_45methodgPgSLSrKI1Meta || {},
    alias: new_45payment_45methodgPgSLSrKI1Meta?.alias || [],
    redirect: new_45payment_45methodgPgSLSrKI1Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/failure/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: indexEdl6pJ8sQCMeta?.name ?? "ghost",
    path: indexEdl6pJ8sQCMeta?.path ?? "/ghost",
    meta: indexEdl6pJ8sQCMeta || {},
    alias: indexEdl6pJ8sQCMeta?.alias || [],
    redirect: indexEdl6pJ8sQCMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/ghost/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrfTBlwqV8Meta?.name ?? "index",
    path: indexKrfTBlwqV8Meta?.path ?? "/",
    meta: indexKrfTBlwqV8Meta || {},
    alias: indexKrfTBlwqV8Meta?.alias || [],
    redirect: indexKrfTBlwqV8Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginTmnLwEeZLsMeta?.name ?? "login",
    path: loginTmnLwEeZLsMeta?.path ?? "/login",
    meta: loginTmnLwEeZLsMeta || {},
    alias: loginTmnLwEeZLsMeta?.alias || [],
    redirect: loginTmnLwEeZLsMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mobile_45appwbRpFqTO7OMeta?.name ?? "mobile-app",
    path: mobile_45appwbRpFqTO7OMeta?.path ?? "/mobile-app",
    meta: mobile_45appwbRpFqTO7OMeta || {},
    alias: mobile_45appwbRpFqTO7OMeta?.alias || [],
    redirect: mobile_45appwbRpFqTO7OMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: indexz4Lt1eiXHAMeta?.name ?? "my-account",
    path: indexz4Lt1eiXHAMeta?.path ?? "/my-account",
    meta: indexz4Lt1eiXHAMeta || {},
    alias: indexz4Lt1eiXHAMeta?.alias || [],
    redirect: indexz4Lt1eiXHAMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: qzFlowB78uKsqEhWMeta?.name ?? "onboard-qzFlow",
    path: qzFlowB78uKsqEhWMeta?.path ?? "/onboard/qzFlow",
    meta: qzFlowB78uKsqEhWMeta || {},
    alias: qzFlowB78uKsqEhWMeta?.alias || [],
    redirect: qzFlowB78uKsqEhWMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/onboard/qzFlow.vue").then(m => m.default || m)
  },
  {
    name: onboardingG4vPkiHtCLMeta?.name ?? "onboarding",
    path: onboardingG4vPkiHtCLMeta?.path ?? "/onboarding",
    meta: onboardingG4vPkiHtCLMeta || {},
    alias: onboardingG4vPkiHtCLMeta?.alias || [],
    redirect: onboardingG4vPkiHtCLMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: player_45test8bpnBwREpeMeta?.name ?? "player-test",
    path: player_45test8bpnBwREpeMeta?.path ?? "/player-test",
    meta: player_45test8bpnBwREpeMeta || {},
    alias: player_45test8bpnBwREpeMeta?.alias || [],
    redirect: player_45test8bpnBwREpeMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/player-test.vue").then(m => m.default || m)
  },
  {
    name: playlistknTQglgezfMeta?.name ?? "playlist",
    path: playlistknTQglgezfMeta?.path ?? "/playlist",
    meta: playlistknTQglgezfMeta || {},
    alias: playlistknTQglgezfMeta?.alias || [],
    redirect: playlistknTQglgezfMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/playlist.vue").then(m => m.default || m)
  },
  {
    name: questionsUKxSB8wDWYMeta?.name ?? "questions",
    path: questionsUKxSB8wDWYMeta?.path ?? "/questions",
    meta: questionsUKxSB8wDWYMeta || {},
    alias: questionsUKxSB8wDWYMeta?.alias || [],
    redirect: questionsUKxSB8wDWYMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: _91token_93lUvSAm01OAMeta?.name ?? "reset-password-token",
    path: _91token_93lUvSAm01OAMeta?.path ?? "/reset-password/:token()",
    meta: _91token_93lUvSAm01OAMeta || {},
    alias: _91token_93lUvSAm01OAMeta?.alias || [],
    redirect: _91token_93lUvSAm01OAMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexBy0XpC5nIsMeta?.name ?? "reset-password",
    path: indexBy0XpC5nIsMeta?.path ?? "/reset-password",
    meta: indexBy0XpC5nIsMeta || {},
    alias: indexBy0XpC5nIsMeta?.alias || [],
    redirect: indexBy0XpC5nIsMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexku8Usy48mCMeta?.name ?? "search",
    path: indexku8Usy48mCMeta?.path ?? "/search",
    meta: indexku8Usy48mCMeta || {},
    alias: indexku8Usy48mCMeta?.alias || [],
    redirect: indexku8Usy48mCMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: selectErbur3FmKrMeta?.name ?? "select",
    path: selectErbur3FmKrMeta?.path ?? "/select",
    meta: selectErbur3FmKrMeta || {},
    alias: selectErbur3FmKrMeta?.alias || [],
    redirect: selectErbur3FmKrMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/select.vue").then(m => m.default || m)
  },
  {
    name: a_45_91artistSlug_93hYnXsJCoJSMeta?.name ?? "share-a-artistSlug",
    path: a_45_91artistSlug_93hYnXsJCoJSMeta?.path ?? "/share/a-:artistSlug()",
    meta: a_45_91artistSlug_93hYnXsJCoJSMeta || {},
    alias: a_45_91artistSlug_93hYnXsJCoJSMeta?.alias || [],
    redirect: a_45_91artistSlug_93hYnXsJCoJSMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/share/a-[artistSlug].vue").then(m => m.default || m)
  },
  {
    name: c_45_91collectionId_93nxkc8ftGMxMeta?.name ?? "share-c-collectionId",
    path: c_45_91collectionId_93nxkc8ftGMxMeta?.path ?? "/share/c-:collectionId()",
    meta: c_45_91collectionId_93nxkc8ftGMxMeta || {},
    alias: c_45_91collectionId_93nxkc8ftGMxMeta?.alias || [],
    redirect: c_45_91collectionId_93nxkc8ftGMxMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/share/c-[collectionId].vue").then(m => m.default || m)
  },
  {
    name: v_45_91videoSlug_93yKF3LrXUm8Meta?.name ?? "share-v-videoSlug",
    path: v_45_91videoSlug_93yKF3LrXUm8Meta?.path ?? "/share/v-:videoSlug()",
    meta: v_45_91videoSlug_93yKF3LrXUm8Meta || {},
    alias: v_45_91videoSlug_93yKF3LrXUm8Meta?.alias || [],
    redirect: v_45_91videoSlug_93yKF3LrXUm8Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/share/v-[videoSlug].vue").then(m => m.default || m)
  },
  {
    name: indexqahUT167iUMeta?.name ?? "signup-base-club",
    path: indexqahUT167iUMeta?.path ?? "/signup/base-club",
    meta: indexqahUT167iUMeta || {},
    alias: indexqahUT167iUMeta?.alias || [],
    redirect: indexqahUT167iUMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/base-club/index.vue").then(m => m.default || m)
  },
  {
    name: plans6LHeg4T8SQMeta?.name ?? "signup-base-club-plans",
    path: plans6LHeg4T8SQMeta?.path ?? "/signup/base-club/plans",
    meta: plans6LHeg4T8SQMeta || {},
    alias: plans6LHeg4T8SQMeta?.alias || [],
    redirect: plans6LHeg4T8SQMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/base-club/plans.vue").then(m => m.default || m)
  },
  {
    name: indexkLAxT9XkQxMeta?.name ?? "signup",
    path: indexkLAxT9XkQxMeta?.path ?? "/signup",
    meta: indexkLAxT9XkQxMeta || {},
    alias: indexkLAxT9XkQxMeta?.alias || [],
    redirect: indexkLAxT9XkQxMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: plansWpBSZLFN1AMeta?.name ?? "signup-plans",
    path: plansWpBSZLFN1AMeta?.path ?? "/signup/plans",
    meta: plansWpBSZLFN1AMeta || {},
    alias: plansWpBSZLFN1AMeta?.alias || [],
    redirect: plansWpBSZLFN1AMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/plans.vue").then(m => m.default || m)
  },
  {
    name: indexOgcBEW2f8BMeta?.name ?? "signup-promo",
    path: indexOgcBEW2f8BMeta?.path ?? "/signup/promo",
    meta: indexOgcBEW2f8BMeta || {},
    alias: indexOgcBEW2f8BMeta?.alias || [],
    redirect: indexOgcBEW2f8BMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/promo/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3MoGDnZhIMeta?.name ?? "signup-select",
    path: indexh3MoGDnZhIMeta?.path ?? "/signup/select",
    meta: indexh3MoGDnZhIMeta || {},
    alias: indexh3MoGDnZhIMeta?.alias || [],
    redirect: indexh3MoGDnZhIMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/select/index.vue").then(m => m.default || m)
  },
  {
    name: plans0BzvjAhVOpMeta?.name ?? "signup-select-plans",
    path: plans0BzvjAhVOpMeta?.path ?? "/signup/select/plans",
    meta: plans0BzvjAhVOpMeta || {},
    alias: plans0BzvjAhVOpMeta?.alias || [],
    redirect: plans0BzvjAhVOpMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/signup/select/plans.vue").then(m => m.default || m)
  },
  {
    name: start7FvBlKZOU2Meta?.name ?? "start",
    path: start7FvBlKZOU2Meta?.path ?? "/start",
    meta: start7FvBlKZOU2Meta || {},
    alias: start7FvBlKZOU2Meta?.alias || [],
    redirect: start7FvBlKZOU2Meta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/start.vue").then(m => m.default || m)
  },
  {
    name: indexF3W1kWEAszMeta?.name ?? "success",
    path: indexF3W1kWEAszMeta?.path ?? "/success",
    meta: indexF3W1kWEAszMeta || {},
    alias: indexF3W1kWEAszMeta?.alias || [],
    redirect: indexF3W1kWEAszMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodYKgrP8iRwKMeta?.name ?? "success-new-payment-method",
    path: new_45payment_45methodYKgrP8iRwKMeta?.path ?? "/success/new-payment-method",
    meta: new_45payment_45methodYKgrP8iRwKMeta || {},
    alias: new_45payment_45methodYKgrP8iRwKMeta?.alias || [],
    redirect: new_45payment_45methodYKgrP8iRwKMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/success/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: terms_of_useN0VVkk3T6SMeta?.name ?? "terms_of_use",
    path: terms_of_useN0VVkk3T6SMeta?.path ?? "/terms_of_use",
    meta: terms_of_useN0VVkk3T6SMeta || {},
    alias: terms_of_useN0VVkk3T6SMeta?.alias || [],
    redirect: terms_of_useN0VVkk3T6SMeta?.redirect,
    component: () => import("/codebuild/output/src3606977093/src/frontend/pages/terms_of_use.vue").then(m => m.default || m)
  }
]