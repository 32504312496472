import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore()
  const authCookie = useAuthCookie()
  const { origin } = useRequestURL()

  console.log('CMS Middleware - Current origin:', origin)

  // Check if we're on an allowed development URL
  const allowedUrls = [
    'https://frontend-dev-team.watchlucid.com',
    'https://frontend-pre-dev-team.watchlucid.com',
    'http://localhost:7002',
    'http://localhost:3000',
    'http://localhost:3001'
  ]
  const isAllowedUrl = allowedUrls.includes(origin)
  console.log('CMS Middleware - Is allowed URL?', isAllowedUrl)

  if (!isAllowedUrl) {
    console.log('CMS Middleware - Redirecting: Not an allowed URL')
    return navigateTo('/')
  }

  // Check if we have a valid auth token
  if (!authCookie?.accessToken) {
    console.log('CMS Middleware - Redirecting: No auth token')
    return navigateTo('/')
  }

  // Parse JWT and authenticate
  authStore.authenticate(true)
  authStore.parseJWT(authCookie.accessToken)

  console.log('CMS Middleware - Access granted')
}) 