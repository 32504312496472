import { Platform } from '~/services/drm/types'
// import { Capacitor } from '@capacitor/core'
import { ref } from 'vue'

interface DeviceInfo {
    isWebSafari: boolean
    isWeb: boolean
    isIOS: boolean
    isAndroid: boolean
    isMobile: boolean
    platform: Platform
    userAgent: string
}

export function useDevice(): DeviceInfo {
    /* ORIGINAL IMPLEMENTATION - Uncomment when native support is ready
    // Get native platform from Capacitor first
    const nativePlatform = Capacitor.getPlatform() as 'ios' | 'android' | 'web'
    
    // Initialize platform ref first
    const platformRef = ref<Platform>(Platform.web) // Default to web

    // Determine platform
    if (nativePlatform === 'web') {
        // Check for Safari when on web platform
        const isSafari = /^(?!.*chrome|android).*safari/i.test(navigator.userAgent)
        platformRef.value = isSafari ? Platform.webSafari : Platform.web
    } else {
        platformRef.value = nativePlatform as Platform
    }

    // Derive other flags from platform
    const isWeb = platformRef.value === Platform.web || platformRef.value === Platform.webSafari
    const isWebSafari = platformRef.value === Platform.webSafari
    const isIOS = platformRef.value === Platform.ios
    const isAndroid = platformRef.value === Platform.android
    const isMobile = isIOS || isAndroid

    return {
        isWebSafari,
        isWeb,
        isIOS,
        isAndroid,
        isMobile,
        platform: platformRef.value,
        userAgent: navigator.userAgent
    }
    */

    // TODO TEMPORARY: Force web platform but keep mobile app and Safari detection
    const platformRef = ref<Platform>(Platform.web)
    
    const userAgent = navigator.userAgent.toLowerCase()
    const isSafari = (
        /safari/.test(userAgent) || 
        userAgent.includes('iosmobile')  // Treat iOS WebView like Safari
    ) && !/chrome|android/.test(userAgent)

    if (isSafari) {
        platformRef.value = Platform.webSafari  // This ensures FairPlay DRM
    }
    
    const isMobileApp = checkIfMobileApp()
    
    return {
        isWebSafari: isSafari,
        isWeb: true,  // Keep web player
        isIOS: false, // Keep native features off
        isAndroid: false, // Keep native features off
        isMobile: isMobileApp, // Keep mobile UI
        platform: platformRef.value, // This will be webSafari for iOS WebView
        userAgent: navigator.userAgent
    }
}

export function checkIfMobileApp() {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
        userAgent.includes('iosmobile') || 
        userAgent.includes('androidmobile')
    );
} 